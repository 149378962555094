import { useState, useEffect } from 'react';

import useAxios from 'hooks/axios';

import { mapCity } from './mapper';

const useDefaultCity = () => {
    const axios = useAxios();
    const [data, setData] = useState(null);

    useEffect(() => {
        const getDefaultCity = async() => {
            const res = await axios.get('Contenido/ProvinciaDefaultCompraPresencial')
            
            if (res.data) {
                const mappedData = mapCity(res.data);
                setData(mappedData);
            }
        };

        getDefaultCity();
    }, []);

    return data;
}

export { useDefaultCity };